<script>
export default {
  name: "TheEnd"
}
</script>

<template>
  <div
    style="
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      text-align: center;
      font-size: 2rem;
      padding: 10px;
      max-width: 1000px;
    "
  >
    Poštovani, Dostupna je nova verzija aplikacije. Možete je preuzeti putem
    Google Play prodavnice na sledećem linku: <a href="https://play.google.com/store/apps/details?id=hr.masterfb.menu">https://play.google.com/store/apps/details?id=hr.masterfb.menu</a>
    Podatke za prijavu dostaviće vam vaš MasterFB agent. Ukoliko imate bilo kakva dodatna pitanja,
    slobodno nas kontaktirajte putem e-mail adrese:<a href="mailto:info@master-fb.com">info@master-fb.com.</a>
    Srdačan
    pozdrav, MasterFB tim
  </div>
</template>

<style scoped lang="scss">

</style>
