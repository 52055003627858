<template>
  <div class="home">
    <div v-if="client" class="client flex-center flex-column">
      <v-container fluid class="home-1 py-8 px-0" style="z-index: 2"></v-container>
      <TheEnd style="z-index: 4"/>
    </div>
  </div>
</template>

<script>

import localforage from "localforage";
import TheEnd from "@/views/TheEnd.vue";

export default {
  name: "Home",
  components: {TheEnd},
  data() {
    return {
      counter: 0,
      current: null,
      setAdTimeout: null,
      menuTimeout: null,
      length_ads: 0,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    grouped_products() {
      return this.$store.getters.getGroupedProducts;
    },
    supportedLangs() {
      return this.$store.getters.getsupportedLangs;
    },
    ads() {
      return this.$store.getters.getHomeAds;
    },
    client() {
      return this.$store.getters.getClient;
    },
    showAds() {
      return this.$store.getters.showAds;
    },
    productTypes() {
      let products = [];
      this.$store?.getters?.getProductTypes?.forEach((element) => {
        if (this.grouped_products[element.id]) {
          products.push(element);
        }
      });
      return products;
    },
  },
  methods: {
    goTo(value) {
      this.$router.push(value);
    },
    setCurrent() {
      let time = 4000
      this.length_ads = this.ads?.length
      if (this.ads?.length > 0) {
        let ad = this.ads[this.counter];
        this.current = ad;
        this.incrementCounter();
        if (!this.current.video_url == false) {
          // var vid = document.getElementById("myVideo");
          return
        } else {
          time = 4000
        }
      }
      this.setAdTimeout = setTimeout(() => {
        this.setCurrent();
      }, time);
    },
    endVideo() {
      this.setCurrent()
    },
    incrementCounter() {
      if (this.counter === this.ads.length - 1) {
        this.counter = 0;
      } else {
        this.counter++;
      }
    },
    async setLang(lang) {
      await this.$store.dispatch("setLang", lang);
      this.$i18n.locale = lang;
    },
    closeAd() {
      this.$store.dispatch("showAds", false);
      this.startTimeout();
    },
    startTimeout() {
      this.menuTimeout = setTimeout(() => {
        clearTimeout(this.setAdTimeout);
        this.setCurrent();
        // this.$store.commit("clearFavs");
        this.$store.dispatch("showAds", true);
      }, 1*60*1000);
    },
  },
  async created() {
    localStorage.vina_hash = this.$route.params.hash;
    let fromServer = this.$route.params.fromServer == "true";
    if (fromServer) {
      localStorage.removeItem('vinaLang')
      await localforage.clear()
    }
    await this.$store.dispatch("getsupportedLangs", {
      fromServer: fromServer,
      hash: this.$route.params.hash,
    });
    await this.$store.dispatch("getAds", {
      fromServer: fromServer,
      hash: this.$route.params.hash,
    });

    setTimeout(() => {
      this.$store.dispatch("getProductTypes", {
        fromServer: fromServer,
      });
      this.$store.dispatch("getClient", {
        fromServer: fromServer,
        hash: this.$route.params.hash,
      });
      this.$store.dispatch("getFilters", {
        fromServer: fromServer,
      });
      this.$store.dispatch("getRegions", {
        fromServer: fromServer,
      });
      this.$store.dispatch("getCountries", {
        fromServer: fromServer,
      });
      this.$i18n.locale = this.lang?.abbreviation;
      if (localStorage.vinaLang) {
        this.setLang(localStorage.vinaLang)
      }
    }, 500);
    setInterval(() => {
      this.$store.dispatch("syncClientStatistics", {
        fromServer: fromServer,
      });
    }, 1000 * 30);
    if (this.ads?.length > 0) {
      this.startTimeout();
    }
  },
  destroyed() {
    clearTimeout(this.menuTimeout);
  },
  watch: {
    ads: function (newVal, oldVal) {
      if (oldVal?.length === 0 && newVal?.length > 0) {
        this.startTimeout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client {
  min-height: 100vh;
  position: relative;
}

.home-overlay {
  position: absolute;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  z-index: 4;
}

.big-ad {
  width: 100% !important;
  max-height: 60vh !important;
  width: auto;
  object-fit: cover;
}

.big-ad-full {
  width: 100% !important;
  height: auto;
  object-fit: cover;
}

.home-1 {
  height: 45vh;
  position: fixed;
  top: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)),
  url("../assets/img/vinograd.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.home-2 {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-logo {
  max-height: 200px;
}

.home-title {
  font-size: 50px;
}

.home-col {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-item {
  width: 100%;
  height: 35vh;
  background-color: transparent;
  //border: 1px solid #272c33;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  * {
    transition: 0.3s ease;
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 45px;
    text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;

    color: #d7c194;
  }

  &:hover {
    * {
      color: #fff !important;
      transition: 0.3s ease;
    }
  }
}

.ad-image {
  max-height: 30vh !important;
}

//.language {
//  max-height: 60vh;
//  overflow-y: scroll;
//}

@media (max-width: 1160px) {
  .home-title {
    font-size: 40px;
    text-align: center;
  }

  .home-logo {
    height: 50px;
  }

  .menu-item {
    height: 35vh;
  }
}
</style>
